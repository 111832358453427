<template>
	<GridGallery
		v-if="hasGridImages"
		:key="renderKey"
		:grid-images="gridImages"
		:column-count="columnCount"
		:column-gap="columnGap"
		:is-masonry-layout="isMasonryLayout"
	/>
	<EmptyGallery v-else />
</template>

<script>
import { computed } from '@vue/composition-api';

import { isDesktopSafari } from '@/utils/browserIdentifiers';
import GridGallery from '@user/components/grid-components/gallery/GridGallery.vue';
import { useGridGallery } from '@user/components/grid-components/gallery/useGridGallery';

import EmptyGallery from './EmptyGallery.vue';

export default {
	name: 'GridGalleryProviderBuilder',
	components: {
		GridGallery,
		EmptyGallery,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const {
			gridImages,
			columnCount,
			columnGap,
			isMasonryLayout,
		} = useGridGallery(props);

		return {
			hasGridImages: computed(() => gridImages.value.length > 0),
			gridImages: computed(() => gridImages.value.map((image) => ({
				...image,
				preventDrag: true,
			}))),
			columnCount,
			columnGap,
			isMasonryLayout,
		};
	},
	data() {
		return { renderKey: 0 };
	},
	watch: {
		columnGap() {
			// Rerender on each change in safari or else it wont rerender changed gaps
			if (isDesktopSafari) {
				this.renderKey += 1;
			}
		},
	},
};
</script>
